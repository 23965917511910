import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const DuneVisualization = (data) => {
    console.log(data);
    return (
        <Container className="mt-2">
            <Card className="px-3 py-4">
                <Row className="justify-content-center my-2">
                    <Col xs="12" md="9">
                        <object type="text/html" data={data.data}
                            style={{ width: '100%', height: '100%' }} />
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}

export default DuneVisualization;
