import classNames from 'classnames';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import classes from './JadeSummary.module.scss';

// console.log('classes', classes);

export default function JadeSummary({ summary }) {
  return (
    <Container className={classes.JadeSummary}>
      <Row>
        <Col>
          <h5 className="mb-1 text-muted">Summary Table</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={classNames('text-center', 'p-3', classes.jadeAVAX)} border="light">
            <h2>{summary.jadeAVAX}</h2>
            <div className="text-nowrap">Jade on Avalanche</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center', 'p-3', classes.sjadeAVAX)} border="light">
            <h2>{summary.sjadeAVAX}</h2>
            <div className="text-nowrap">sJade on Avalanche</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.jadeBSC)} border="light">
            <h2>{summary.jadeBSC}</h2>
            <div className="text-nowrap">Jade on BSC</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.sjadeBSC)} border="light">
            <h2>{summary.sjadeBSC}</h2>
            <div className="text-nowrap">sJade on BSC</div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={classNames('text-center p-3', classes.d1v1Bond)} border="light">
            <h2>{summary.d1v1Bond}</h2>
            <div className="text-nowrap">Discount 1 Bonds</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.d1v2Bond)} border="light">
            <h2>{summary.d1v2Bond}</h2>
            <div className="text-nowrap">Discount 1v2 Bonds</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.d2v1Bond)} border="light">
            <h2>{summary.d2v1Bond}</h2>
            <div className="text-nowrap">Discount 2 Bonds</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.totalJade)} border="light">
            <h2>{summary.totalJade}</h2>
            <div className="text-nowrap">Total Jade</div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

