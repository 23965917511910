@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.header {
    background-color: var(--bs-light);
    border-bottom: 1px solid $gray-200;

    .logo {
        color: #183b56;
        font-family: "Orbitron", Helvetica;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        flex: 1;
    }

    .darkModeButton {
        padding: 12px;
        margin-right: -12px;
    }

    @include media-breakpoint-down(sm) {
        .logo {
            font-size: 24px;
            margin-right: 0;
        }

        .costBasisButton {
            padding: 12px;
        }
    }
}

[data-theme="dark"] .header {
    background-color: var(--bs-secondary-bg);
    border-bottom: 1px solid $gray-800;

    .logo {
        color: #ffc107;
    }
}