.invested { background: #e9f4d3 !important; }
.sold { background: #E8EEF9 !important }
.netInvested { background: #EAE9EF !important }
.holding { background: #E1E9EC !important }
.costBasis { background: #ECE1E3 !important }


// https://pinetools.com/darken-color to 50%
[data-theme="dark"] {
    .invested, .sold, .netInvested, .holding, .costBasis { color: var(--bs-light); }
    .invested { background: #88b62d !important; }
    .sold { background: #3163be !important; }
    .netInvested { background: #696388 !important; }
    .holding { background: #597f8d !important; }
    .costBasis { background: #8d5962 !important; }
}

.summary {
    :global .row > * {
        padding: 6px;
        margin: 0;

        .card {
            margin-left: 0;
            margin-right: 0;   
        }
    }
}