import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router';
import { Card, Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import Layout from '~/components/Layout';
import SimplePlot from "~/components/SimplePlot";
import PlotlyExample from "../components/PlotlyExample";

const ClaimsWindow = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [address, setAddress] = useState(params.address);
  const [data, setData] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  let handleSubmit = async (e) => {
    if (isLoading) return;
    if (e) {
      e.preventDefault();
    }
    // 0xE88021ec1F9E9EDB59A2C2E32C77b58823218aD5
    // const url = `https://cryprod.pythonanywhere.com/api/JadeWalletBalance/?wallet=${address}`;
    // const url = `http://cryprod.pythonanywhere.com/api/getJadeLiquidationClaims`;
    const url = `http://cryprod.pythonanywhere.com/api/getJadeLiquidationClaimsPlotly`;

    setIsLoading(true);
    try {
      const response = await fetch(url, { method: 'get', mode: 'cors' });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else if (response.status === 200) {
        setData(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
    console.log(url)
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  return (
    <>
      <Layout>
        <PlotlyExample ></PlotlyExample>
        <a href="https://arbiscan.io/token/0xaf88d065e77c8cc2239327c5edb3a432268e5831?a=0xfdfd717f52a4174a992d595d0c8930ade3563138" target="_blank" className="text-muted d-block">See all the transactions</a>

        {/* {data ?
          // <SimplePlot summary={data} title="Claims Data" ></SimplePlot> :
          <PlotlyExample ></PlotlyExample> :
          <>
            <Container>
              <Row className="justify-content-md-center">
                <Col lg="7">
                  <Card className="text-left p-4">
                    <Card.Body>
                      <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                        <Button
                          variant={isLoading ? 'secondary' : 'success'}
                          className="block mt-2"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className="me-2"
                            />
                          }
                          Click Here
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        } */}
      </Layout>
    </>
  );
}

export default ClaimsWindow;
