@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

table.transactions {
    width: 100%;
    margin: auto;

    thead {
        background: var(--bs-tertiary-bg);
        white-space: nowrap;
        font-weight: 700;
        font-size: 12px;

        th {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .hash,
    .wallet {
        overflow: hidden;
        text-overflow: ellipsis;

        >* {
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    :global .badge {
        padding: .5rem;
        border-radius: 4px;

        &.bg-bond {
            background: #2e7cf61a;
            color: #2e7cf6;
        }

        &.bg-airdrop {
            background: #2e7cf61a;
            color: #2e7cf6;
        }

        &.bg-bought {
            background: #16c35b1a;
            color: #16c35b;
        }

        &.bg-sold {
            background: #24bcc61a;
            color: #24BDC7;
        }

        &.bg-smrt4smrtr {
            background: #24bcc61a;
            color: #24BDC7;
        }

        &.bg-pair {
            background: #ff99001a;
            color: #f90;
        }

        &.bg-mint {
            background: #7373731a;
            color: #737373;
        }

        &.bg-rewards {
            background: #7373731a;
            color: #737373;
        }

        &.bg-masterchef {
            background: #7373731a;
            color: #737373;
        }

        &.bg-burn {
            background: #7373731a;
            color: #737373;
        }

    }

    @include media-breakpoint-down(lg) {
        font-size: .75rem;
        display: block;

        thead {
            display: block;
            font-size: .66rem;

            white-space: nowrap;


            tr {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                th {
                    border: none;

                    &:not(:last-child):not(:first-child) {
                        padding-bottom: 0;
                    }
                }

                .block {
                    order: 0;
                    flex: 1;
                }

                .hash {
                    order: -1;
                    min-width: 100%;
                }

                .network,
                .tag,
                .invested,
                .sold {
                    flex: 1;
                }

                .wallet {
                    min-width: 100%;
                }
            }
        }

        tbody {
            display: block;

            tr {
                display: flex;
                flex-wrap: wrap;

                td {
                    display: flex;
                    align-items: center;
                }

                .block {
                    order: 0;
                    flex: 1;
                }

                .network {
                    flex: 1;
                }

                .hash {
                    order: -1;
                    min-width: 100%;
                }

                .tag {
                    flex: 1;
                }

                .invested {
                    flex: 1;
                }

                .sold {
                    flex: 1;
                }

                .wallet {
                    min-width: 100%;
                }

                td:not(:last-child) {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }
}