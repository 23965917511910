import classNames from 'classnames';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Header from "~/components/Header";
import classes from './Layout.module.scss';

function Layout({ children }) {
    return (
        <div className="min-vh-100 d-flex flex-column">
            <Header className="flex-shrink-1" />
            <div className="flex-grow-1 justify-content-md-center align-items-center d-flex flex-column py-3">
                {children}
                <Container className={classNames(classes.footer, 'text-muted d-flex flex-column flex-md-row justify-content-center mt-4')}>
                    <a href="https://www.snowtrace.io" target="_blank" className="text-muted d-block">Click here to go to snowtrace</a>
                    <span className="separator mx-3 d-none d-md-block"></span>
                    <a href="https://bscscan.com/" target="_blank" className="text-muted d-block">Click here to go to bscscan</a>
                    <span className="separator mx-3 d-none d-md-block"></span>
                    <a href="https://arbiscan.io/" target="_blank" className="text-muted d-block">Click here to go to arbiscan</a>
                </Container>
            </div>
        </div>
    )
}

export default Layout;
