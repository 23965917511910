import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router';
import SimplePlot from "../SimplePlot";

function putTogetherData(data) {
    var date = [];
    var TreasuryWallet = [];
    var JadePrice = [];
    var TotalSupply = [];

    let output = [];

    // BUSD Bond JADE Balance	1093.211955857
    // BuyBot JADE Balance	128408.19959474
    // DAO Owned JADE Balance	710491.74172121
    // JADE in Treasury Wallet	217260.14509725102
    // Jade Market Cap	20107206.66730057
    // LP Bond JADE Balance	57.51722676399999
    // PCS Liquidity Pool JADE Balance	22473.953154882
    // Staked JADE (sJADE) Balance	654772.879773735
    // Total Circulating Supply	962009.976394117
    // Total Supply	1802452.653163621
    // Unstaked Circulating Supply	67502.99836824903
    // unix_time	1651019246.4883144
    // wBNB Bond JADE Balance	90.935881647

    // Calculate chart if you double your position
    for (let dataObj of Object.keys(data)) {
        var eachItem = data[dataObj];
        let tempDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(eachItem.unix_time * 1000);
        date.push(tempDate);
        TreasuryWallet.push(eachItem['JADE in Treasury Wallet']);
        JadePrice.push(eachItem['Jade Price']);
        TotalSupply.push(eachItem['Total Supply']);
    };
    output = ({
        labels: date,
        datasets: [
            {
                label: "JADE in Treasury Wallet",
                data: TreasuryWallet,
                fill: false,
            },
            {
                label: "Total Supply",
                data: TotalSupply,
                fill: false,
            }
        ]
    });

    return output;
}

function putTogetherData2(data) {
    var date = [];
    var FairMarketValue = [];
    var JadePrice = [];
    var PercentStaked = [];
    let output = [];


    // Fair Market Value	85.8303675492995
    // Jade Price	20.901245476339046
    // Percentage of JADE Staked in User Wallets	90.65412532647541
    // unix_time	1651019246.4883144


    // Calculate chart if you double your position
    for (let dataObj of Object.keys(data)) {
        var eachItem = data[dataObj];
        let tempDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(eachItem.unix_time * 1000);
        date.push(tempDate);
        FairMarketValue.push(eachItem['Fair Market Value']);
        JadePrice.push(eachItem['Jade Price']);
        PercentStaked.push(eachItem['Percentage of JADE Staked in User Wallets']);
    };
    output = ({
        labels: date,
        datasets: [
            {
                label: "Fair Market Value",
                data: FairMarketValue,
                fill: false,

            },
            {
                label: "Jade Price",
                data: JadePrice,
                fill: false,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: "% Staked",
                data: PercentStaked,
                fill: false,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    });

    return output;
}

const SJadeUsers = (props) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const url = `https://cryprod.pythonanywhere.com/api/JadeBalancesAll`;
        fetch(url)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.log(error));
    }, []);

    React.useEffect(() => {
        if (data.length !== 0) {
            setIsLoading(false);
        }
    }, [data]);

    const graphData = putTogetherData(data);
    const graph2Data = putTogetherData2(data);


    return (
        <>
            <SimplePlot data={graphData} title="JADE TREASURY BALANCES" />
            <SimplePlot data={graph2Data} title="JADE USERS STATISTICS" />
        </>
    );
}

export default SJadeUsers;
