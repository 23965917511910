@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.footer {
  a {
    color: currentColor;
  }

  .separator {
    display: inline-block;
    height: 30px;
    width: 2px;
    background: $gray-200;
  }
}
