import classNames from 'classnames';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import classes from './Summary.module.scss';

// console.log('classes', classes);

export default function Summary({ summary }) {
  return (
    <Container className={classes.summary}>
      <Row>
        <Col>
          <h5 className="mb-1 text-muted">Summary Table</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={classNames('text-center', 'p-3', classes.invested)} border="light">
            <h2>${summary.invested}</h2>
            <div className="text-nowrap">Total amount invested</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center', 'p-3', classes.sold)} border="light">
            <h2>${summary.sold}</h2>
            <div className="text-nowrap">Total amount sold</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.netInvested)} border="light">
            <h2>${summary.netInvested}</h2>
            <div className="text-nowrap">Net invested</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.holding)} border="light">
            <h2>{summary.holding}</h2>
            <div className="text-nowrap">Jade and sJade in wallet</div>
          </Card>
        </Col>
        <Col>
          <Card className={classNames('text-center p-3', classes.costBasis)} border="light">
            <h2>${summary.costBasis}</h2>
            <div className="text-nowrap">Cost Basis</div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

