import classNames from 'classnames';
import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import SummarySmrtr from '~/components/SummarySmrtr';
import ScenariosSmrtr from "~/components/ScenariosSmrtr";
import classes from './Results.module.scss';

function badgeClass(tag) {
  if (tag.includes("Bond")) {
    return 'bond';
  }
  else if (tag.includes("Bought")) {
    return 'bought';
  }
  else if (tag.includes("Sold")) {
    return 'sold';
  }
  else if (tag.includes("Masterchef")) {
    return 'masterchef';
  }
  else if (tag.includes("LP")) {
    return 'pair';
  }
  else if (tag.includes("Mint")) {
    return 'mint';
  }
  else if (tag.includes("Burn")) {
    return 'burn';
  }
  else if (tag.includes("Airdrop")) {
    return 'airdrop';
  }
  else if (tag.includes("Rewards")) {
    return 'rewards';
  }
  else if (tag.includes("SMRT for SMRTR")) {
    return 'smrt4smrtr';
  }
  return null;
}

const ResultsSmrtr = (props) => {
  const { summary, transactions } = props;

  const [tryScenario, setTryScenario] = useState(false);
  const [smrtrPrice, setSmrtrPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      let res = await fetch("https://cryprod.pythonanywhere.com/api/SmrtrCostBasis/getSmrtrPrice", { method: 'get', mode: 'cors' });
      let resJson = await res.json();

      if (res.status === 200) {
        setSmrtrPrice(resJson.smrtrPrice);
        setTryScenario(true);
      }
    } catch (err) {
      alert(err);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      <Card border="light" className="py-4 px-2">
        <h3 className="display-3 mb-4 text-center">Smrtr Cost Basis</h3>

        <SummarySmrtr summary={summary} />

        {tryScenario ?
          <ScenariosSmrtr summary={summary} smrtrPrice={smrtrPrice} /> :
          <div className="text-center mt-4">
            <Button disabled={isLoading} onClick={handleSubmit} variant="outline-success">Want to run some scenarios?</Button>
          </div>
        }
      </Card>

      {!tryScenario &&
        <Card border="light" className="py-4 px-2 mt-4">
          <Container>
            <h5 className="my-2 text-muted">Important Transactions</h5>
            <Table className={classNames('small', classes.transactions)}>
              <thead>
                <tr>
                  <th className={classes.block}>Block<span className="d-none d-sm-inline"> Number</span></th>
                  <th className={classes.network}>Network</th>
                  <th className={classes.hash}>Hash</th>
                  <th className={classes.tag}>Tag</th>
                  <th className={classes.invested}><span className="d-none d-sm-inline">Amount </span>Invested</th>
                  <th className={classes.sold}><span className="d-none d-sm-inline">Amount </span>Sold</th>
                  <th className={classes.wallet}>Wallet</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) =>
                  <tr key={index}>
                    <td className={classes.block}>{transaction.blockNumber}</td>
                    <td className={classes.network}><span title={transaction.network}>{transaction.network}</span></td>
                    <td className={classes.hash}>
                      {transaction.network == "avalanche" &&
                        <a
                          href={`https://snowtrace.io/tx/${transaction.hash}`}
                          target="_blank"
                          title={transaction.hash}
                        >{transaction.hash}</a>
                      }
                      {transaction.network == "bsc" &&
                        <a
                          href={`https://bscscan.com/tx/${transaction.hash}`}
                          target="_blank"
                          title={transaction.hash}
                        >{transaction.hash}</a>
                      }
                    </td>
                    <td className={classes.tag}>
                      <Badge bg={badgeClass(transaction.tag)}>{transaction.tag}</Badge>
                    </td>
                    <td className={classes.invested}>{transaction.amountInvested}</td>
                    <td className={classes.sold}>{transaction.amountSold}</td>
                    <td className={classes.wallet}><span title={transaction.Wallet}>{transaction.Wallet}</span></td>

                  </tr>
                )}
              </tbody>
            </Table>
          </Container>
        </Card>
      }
    </Container>
  );
}

export default ResultsSmrtr;
