import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router';
import { Card, Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { InfoCircle } from 'react-bootstrap-icons';
import Layout from '~/components/Layout';
import ResultsSmrtr from "~/components/ResultsSmrtr";

const SmrtrCostBasis = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [address, setAddress] = useState(params.address);
  const [data, setData] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  let handleSubmit = async (e) => {
    if (isLoading) return;
    if (e) {
      e.preventDefault();
    }
    // 0xE88021ec1F9E9EDB59A2C2E32C77b58823218aD5
    const url = `https://cryprod.pythonanywhere.com/api/SmrtrCostBasis/?wallet=${address}`;

    setIsLoading(true);
    try {
      const response = await fetch(url, { method: 'get', mode: 'cors' });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else if (response.status === 200) {
        if (!params.address) {
          history.push(`/SmrtrCostBasis/${address}`, data);
        } else if (!location.state) {
          history.replace(`/SmrtrCostBasis/${address}`, data);
        }
        setData(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.address) {
      handleSubmit();
    }
  }, []);

  return (
    <Layout>
      {data ?
        <ResultsSmrtr summary={data.summary} transactions={data.table.data} ></ResultsSmrtr> :
        <>
          <h3 className="display-3 mb-4 text-center">Check SMRTr Cost Basis</h3>

          <div className="mb-3">
            <span><InfoCircle /> </span>
            <a href="https://youtu.be/d-06YqNKmfA" target="_blank" className="text-muted">
              Learn how to use this calculator
            </a>
          </div>

          <Container>
            <Row className="justify-content-md-center">
              <Col lg="7">
                <Card className="text-left p-4">
                  <Card.Body>
                    <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                      <h6>Enter your wallets seperated by commas (ie, 0xe34...,0x65...)</h6>
                      <Form.Group className="mb-4 form-floating with-button" controlId="wallet">
                        <Form.Control
                          type="text"
                          placeholder="Wallet Number"
                          value={address}
                          onChange={e => {
                            setAddress(e.target.value)
                            setError(false);
                          }}
                          disabled={isLoading}
                          isInvalid={error}
                        />
                        {address &&
                          <div className="button-wrapper">
                            <button type="reset" tabIndex={-1} className="btn-clear" onClick={() => {
                              setAddress('')
                              setError(false)
                            }}>
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.41413 5.99976L11.7071 1.70676C12.0981 1.31576 12.0981 0.683762 11.7071 0.292762C11.3161 -0.0982383 10.6841 -0.0982383 10.2931 0.292762L6.00013 4.58576L1.70713 0.292762C1.31613 -0.0982383 0.684128 -0.0982383 0.293128 0.292762C-0.0978721 0.683762 -0.0978721 1.31576 0.293128 1.70676L4.58613 5.99976L0.293128 10.2928C-0.0978721 10.6838 -0.0978721 11.3158 0.293128 11.7068C0.488128 11.9018 0.744128 11.9998 1.00013 11.9998C1.25613 11.9998 1.51213 11.9018 1.70713 11.7068L6.00013 7.41376L10.2931 11.7068C10.4881 11.9018 10.7441 11.9998 11.0001 11.9998C11.2561 11.9998 11.5121 11.9018 11.7071 11.7068C12.0981 11.3158 12.0981 10.6838 11.7071 10.2928L7.41413 5.99976Z" fill="#757D8A" />
                              </svg>
                            </button>
                          </div>
                        }
                        <Form.Label>
                          Wallet Number
                        </Form.Label>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {error}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        variant={isLoading ? 'secondary' : 'success'}
                        className="block mt-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading &&
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className="me-2"
                          />
                        }
                        Submit
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      }
    </Layout>
  );
}

export default SmrtrCostBasis;
