import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router';
import { Line } from 'react-chartjs-2';
import Layout from '~/components/Layout';
import SJadeUsers from "../components/Plots/SJadeUsers";
import JadeBalances from "../components/Plots/JadeBalances";
import DuneVisualization from "../components/DuneVisualization";



const UserStats = (props) => {

  return (
    < Layout >
      <h3 className="display-3 mb-4 text-center">Various Statistics</h3>
      <DuneVisualization data="https://dune.com/embeds/753323/1363756/8043d5d6-4174-4a77-9858-42302c5eaced" />
      <SJadeUsers />
      <JadeBalances />
    </Layout >
  );
}

export default UserStats;
