import 'regenerator-runtime/runtime';
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import Home from "~/pages/Home";
import UserStats from "~/pages/UserStats";
import JadeBalances from "~/pages/JadeBalances";
import ClaimsWindow from "~/pages/ClaimsWindow";
import SmrtrCostBasis from "~/pages/SmrtrCostBasis";

import "./App.scss";

function App() {
  return (
    <ThemeProvider breakpoints={['xs', 'sm', 'md', 'lg']}>
      <Router>
        <Switch>
          <Route exact path="/" component={ClaimsWindow} />
          <Route exact path="/userstats" component={UserStats} />
          <Route exact path="/JadeCostBasis" component={Home} />
          <Route path="/JadeCostBasis/:address" component={Home} />
          <Route exact path="/SmrtrCostBasis" component={SmrtrCostBasis} />
          <Route path="/SmrtrCostBasis/:address" component={SmrtrCostBasis} />
          <Route exact path="/JadeBalances" component={JadeBalances} />
          <Route path="/JadeBalances/:address" component={JadeBalances} />
          <Route exact path="/ClaimWindow" component={ClaimsWindow} />
          <Route exact path="/ClaimWindowStats" component={ClaimsWindow} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
