import { useState } from 'react';

const root = document.documentElement;

export default function useColorScheme() {
    const storedScheme = window.sessionStorage.getItem('color-scheme');
    const autodetectedScheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    const [scheme, setScheme] = useState(storedScheme || autodetectedScheme || 'light');
    root.setAttribute('data-theme', scheme);

    function toggle() {
        root.classList.remove(scheme);
        const newScheme = (scheme === 'dark') ? 'light' : 'dark';
        root.setAttribute('data-theme', newScheme);

        setScheme(newScheme);
        window.sessionStorage.setItem('color-scheme', newScheme)
    }

    return [scheme, toggle];
}