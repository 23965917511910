import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Button } from "react-bootstrap";
import classes from "./Scenarios.module.scss";

import 'chart.js/auto'
import { Line } from 'react-chartjs-2';

function calcSmrtrCostBasis(netInvested, tokensHeld, currentSmrtrPrice) {
  var data = [];
  // Calculate chart if you double your position
  for (let smrtr = 0; smrtr <= tokensHeld; smrtr = smrtr + 1) {
    const x = parseInt(smrtr + tokensHeld);

    let costBasis =
      (netInvested + currentSmrtrPrice * smrtr) / x;

    data.push({
      smrtr: x,
      costBasis,
    });
  }
  console.log(data)
  return data;
}

function parseNum(numString) {
  return parseFloat(String(numString).replace(/,/g, ''));
}

const ScenariosSmrtr = ({ summary, smrtrPrice }) => {
  const [calcType, setCalcType] = useState(null);
  const [scenarioInput, setScenarioInput] = useState('');
  const [scenarioOutput, setScenarioOutput] = useState("Try a scenario above...");
  const [isLoading, setIsLoading] = useState(false);

  const data = calcSmrtrCostBasis(
    parseNum(summary.netInvested),
    parseNum(summary.holding),
    parseNum(smrtrPrice)
  );

  const submit = async (type, url) => {
    setCalcType(type);
    setIsLoading(true);
    try {
      let res = await fetch(url, { method: 'post', mode: 'cors' });
      let resJson = await res.json();
      if (res.status === 200) {
        setScenarioOutput(resJson.scenarioOutput);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  let handleCostBasisClick = async (e) => {
    submit(
      'cost-basis',
      "https://cryprod.pythonanywhere.com/api/SmrtrCostBasis/Scenarios/idealCostBasis/"
      + "?netInvested=" + summary.netInvested.replace(/,/g, '')
      + "&holding=" + summary.holding.replace(/,/g, '')
      + "&idealCostBasis=" + scenarioInput.replace(/,/g, '')
      + "&smrtrPrice=" + smrtrPrice.replace(/,/g, '')
    );
  };

  let handleSmrtrInputClick = async (e) => {
    submit(
      'smrtr',
      "https://cryprod.pythonanywhere.com/api/SmrtrCostBasis/Scenarios/smrtrPurchaseInput/"
      + "?netInvested=" + summary.netInvested.replace(/,/g, '')
      + "&holding=" + summary.holding.replace(/,/g, '')
      + "&smrtrInput=" + scenarioInput.replace(/,/g, '')
      + "&smrtrPrice=" + smrtrPrice.replace(/,/g, '')
    );
  };

  let handleUSDInputClick = async (e) => {
    submit(
      'usd',
      "https://cryprod.pythonanywhere.com/api/SmrtrCostBasis/Scenarios/busdInput/"
      + "?netInvested=" + summary.netInvested.replace(/,/g, '')
      + "&holding=" + summary.holding.replace(/,/g, '')
      + "&busdInput=" + scenarioInput.replace(/,/g, '')
      + "&smrtrPrice=" + smrtrPrice.replace(/,/g, '')
    );
  };

  return (
    <Container className="mt-2">
      <Card className="px-3 py-4">
        <h2 className="text-center">
          <strong>
            Current Price of&nbsp;SMRTr&nbsp;-&nbsp;<span className="text-success">${smrtrPrice}</span>
          </strong>
        </h2>
        <Row className="justify-content-center">
          <Col xs="12" sm="9">
            <Form.Group className="mb-4 with-button" controlId="wallet">
              <Form.Control
                type="number"
                min="1"
                placeholder="40"
                value={scenarioInput}
                onChange={e => {
                  setScenarioInput(e.target.value)
                }}
                disabled={isLoading}
              />
              {scenarioInput &&
                <div className="button-wrapper">
                  <button type="reset" tabIndex={-1} className="btn-clear" onClick={() => {
                    setScenarioInput('')
                  }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.41413 5.99976L11.7071 1.70676C12.0981 1.31576 12.0981 0.683762 11.7071 0.292762C11.3161 -0.0982383 10.6841 -0.0982383 10.2931 0.292762L6.00013 4.58576L1.70713 0.292762C1.31613 -0.0982383 0.684128 -0.0982383 0.293128 0.292762C-0.0978721 0.683762 -0.0978721 1.31576 0.293128 1.70676L4.58613 5.99976L0.293128 10.2928C-0.0978721 10.6838 -0.0978721 11.3158 0.293128 11.7068C0.488128 11.9018 0.744128 11.9998 1.00013 11.9998C1.25613 11.9998 1.51213 11.9018 1.70713 11.7068L6.00013 7.41376L10.2931 11.7068C10.4881 11.9018 10.7441 11.9998 11.0001 11.9998C11.2561 11.9998 11.5121 11.9018 11.7071 11.7068C12.0981 11.3158 12.0981 10.6838 11.7071 10.2928L7.41413 5.99976Z" fill="#757D8A" />
                    </svg>
                  </button>
                </div>
              }
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" sm="9" lg="3" className="d-grid mb-2">
            <Button onClick={handleCostBasisClick} variant={calcType === 'cost-basis' ? 'success' : 'outline-success'} disabled={!scenarioInput || isLoading}>Cost Basis as Input</Button>
          </Col>
          <Col xs="12" sm="9" lg="3" className="d-grid mb-2">
            <Button onClick={handleSmrtrInputClick} variant={calcType === 'smrtr' ? 'success' : 'outline-success'} disabled={!scenarioInput || isLoading}>SMRTRr Purchase as Input</Button>
          </Col>
          <Col xs="12" sm="9" lg="3" className="d-grid mb-2">
            <Button onClick={handleUSDInputClick} variant={calcType === 'usd' ? 'success' : 'outline-success'} disabled={!scenarioInput || isLoading}>USD Purchase as Input</Button>
          </Col>
        </Row>
        <Row className="justify-content-center my-2">
          <Col xs="12" md="9">
            <Line
              data={{
                datasets: [{
                  data,
                  pointHitRadius: 20,
                  pointRadius: 0,
                  borderWidth: 2,
                }],
              }}
              options={{
                responsive: true,
                borderColor: '#198754',

                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      title: (items) => `Cost Basis: ${items[0].raw.costBasis}`,
                      footer: (items) => `SMRTr in wallet: ${items[0].raw.smrtr}`,
                      label: () => null,
                    },
                  },
                  elements: {
                    point: {
                      radius: 0,
                    },
                  },
                },
                parsing: {
                  xAxisKey: 'smrtr',
                  yAxisKey: 'costBasis'
                },
                scales: {
                  x: {
                    type: 'linear',
                    title: {
                      display: true,
                      text: 'SMRTr in wallet',
                    },
                    grid: {
                      color: '#adb5bd30',
                    }
                  },
                  y: {
                    type: 'linear',
                    title: {
                      display: true,
                      text: 'Cost Basis',
                    },
                    grid: {
                      color: '#adb5bd30',
                    }
                  },
                },
              }}
            />
          </Col>
        </Row>

        <h4 className="text-center text-muted mt-3 mb-3">SMRTr Cost Basis Scenarios Output</h4>
        <Row className="justify-content-center">
          <Col xs="9" className="text-center">
            <div className={classes.output}>{scenarioOutput}</div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default ScenariosSmrtr;
