import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router';
import SimplePlot from "../SimplePlot";

function putTogetherData(data) {
    var date = [];
    var sJadeUsers = [];
    let output = [];

    // Calculate chart if you double your position
    for (let dataObj of Object.keys(data)) {
        var eachItem = data[dataObj];
        let tempDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(eachItem.unix_timestamp * 1000);
        date.push(tempDate);
        sJadeUsers.push(eachItem.unique_users);
    };
    output = ({
        labels: date,
        datasets: [
            {
                label: "sJade Users",
                data: sJadeUsers,
                fill: false,
            }
        ]
    });

    return output;
}

const SJadeUsers = (props) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const sJadeContractAddress = '0x94CEA04C51E7D3EC0A4A97AC0C3B3C9254C2AD41';
        const url = `https://cryprod.pythonanywhere.com/api/UniqueUsers?contractAddress=${sJadeContractAddress}`;
        fetch(url)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.log(error));
    }, []);

    React.useEffect(() => {
        if (data.length !== 0) {
            setIsLoading(false);
        }
    }, [data]);

    const graphData = putTogetherData(data);


    return (
        <>

            <SimplePlot data={graphData} title="SJADE USERS OVER TIME" />

        </>
    );
}

export default SJadeUsers;
