import classNames from 'classnames';
import React from "react";
import { Container, Navbar, Button } from "react-bootstrap";
import { Moon, MoonFill } from 'react-bootstrap-icons';
import useColorScheme from '~/util/useColorScheme';
import classes from "./Header.module.scss";

function Header() {
    const [scheme, toggleScheme] = useColorScheme();

    return (
        <Navbar expand="lg" className={classNames(classes.header, 'py-4')}>
            <Container>
                <Navbar.Brand className={classes.logo}>CrypRod's</Navbar.Brand>
                {/* <Button className={classNames(classes.costBasisButton, 'justify-content-end')} href="/JadeCostBasis" variant="success">Jade Cost Basis</Button> */}
                {/* <Button className={classNames(classes.costBasisButton, 'justify-content-end')} href="/SmrtrCostBasis" variant="success">SMRTr Cost Basis</Button> */}
                {/* <Button className={classNames(classes.costBasisButton, 'justify-content-end')} href="/JadeBalances" variant="success">Jade Balances</Button> */}
                <Button className={classNames(classes.costBasisButton, 'justify-content-end')} href="/ClaimWindowStats" variant="success">Claim Window Stats</Button>
                <Button className={classNames(classes.darkModeButton, 'link-secondary')} onClick={toggleScheme} variant="link">{scheme === 'light' ? <Moon /> : <MoonFill />}</Button>
            </Container>
        </Navbar>
    );
}

export default Header;
