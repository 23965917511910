import classNames from 'classnames';
import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import JadeSummary from '~/components/JadeSummary';
import classes from './JadeBalancesResults.module.scss';


const JadeBalancesResults = (props) => {
  const { summary } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container fluid>
      <Card border="light" className="py-4 px-2">
        <h3 className="display-3 mb-4 text-center">Jade Balances</h3>
        <p className="display-8 mb-4 text-center">FWD tokens will be assigned to users 1:1 based on their aggregate JADE balance. All pending and claimable JADE from bonds will be included in the claim drop. When the claim drop is ready, you will need to claim your FWD tokens on the Arbitrum blockchain.</p>

        <JadeSummary summary={summary} />

      </Card>

    </Container>
  );
}

export default JadeBalancesResults;
