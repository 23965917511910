.jadeAVAX {
    background: #e9f4d3 !important;
}

.sjadeAVAX {
    background: #E8EEF9 !important
}

.jadeBSC {
    background: #EAE9EF !important
}

.sjadeBSC {
    background: #E1E9EC !important
}

.d1v1Bond {
    background: #ECE1E3 !important
}

.d1v2Bond {
    background: #E8EEF9 !important
}

.d2v1Bond {
    background: #EAE9EF !important
}

.totalJade {
    background: #E1E9EC !important
}

// https://pinetools.com/darken-color to 50%
[data-theme="dark"] {

    .jadeAVAX,
    .sjadeAVAX,
    .jadeBSC,
    .sjadeBSC,
    .d1v1Bond,
    .d1v2Bond,
    .d2v1Bond,
    .totalJade {
        color: var(--bs-light);
    }

    .jadeAVAX {
        background: #88b62d !important;
    }

    .sjadeAVAX {
        background: #3163be !important;
    }

    .jadeBSC {
        background: #696388 !important;
    }

    .sjadeBSC {
        background: #597f8d !important;
    }

    .d1v1Bond {
        background: #3163be !important;
    }

    .d1v2Bond {
        background: #696388 !important;
    }

    .d2v1Bond {
        background: #597f8d !important;
    }

    .totalJade {
        background: #88b62d !important;
    }
}

.summary {
    :global .row>* {
        padding: 6px;
        margin: 0;

        .card {
            margin-left: 0;
            margin-right: 0;
        }
    }
}