import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import classes from './Summary.module.scss';

const PlotlyExample = () => {
    const [plot, setPlot] = useState(0);

    useEffect(() => {
        fetch('https://cryprod.pythonanywhere.com/api/getJadeLiquidationClaimsPlotly').then(res => res.json()).then(data => { setPlot(data); });
    }, []);
    // console.log(plot)

    return (
        <div className='content'>
            <h2 className="text-center">
                <strong>
                    USDC Claims
                </strong>
            </h2>
            <Plot data={plot.data} layout={plot.layout} />
        </div>
    );
};

export default PlotlyExample;