import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Button } from "react-bootstrap";
import classes from "./SimplePlot.module.scss";
import * as ReactBootStrap from 'react-bootstrap';

import 'chart.js/auto'
import { Line } from 'react-chartjs-2';


const SimplePlot2 = ({ data, title }) => {


  return (
    <Container className="mt-2">
      <Card className="px-3 py-4">
        <Row className="justify-content-center my-2">
          <Col xs="12" md="9">
            <Line data={data}
              options={{
                responsive: true,
                borderColor: '#198754',

                plugins: {
                  legend: {
                    display: true,
                  },
                  elements: {
                    point: {
                      radius: 0,
                    },
                  },
                  title: {
                    display: true,
                    text: title
                  }
                },
              }} />
          </Col>
        </Row>

      </Card>
    </Container>
  );
}

export default SimplePlot2;
